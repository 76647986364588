import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Block, CalculatedBlockAttribute, Input, RexCalculationType} from '@paperlessio/sdk/api/models';
import {PropertyResult} from '@rex/rex-core';

export interface SingleCalculatedBlockAttribute {
  virtualSlug: string;
  attributeName: string;
  value: any;
}

@Injectable({providedIn: 'root'})
export class CalculatedBlockAttributeStore implements OnDestroy {
  private calculatedBlockAttributeValues = new ReplaySubject<SingleCalculatedBlockAttribute>(1);
  private internalCalculatedBlockAttributeValues: Record<string, Record<string, ReplaySubject<any>>> = {};
  private internalValidationRuleResults: Record<string, Record<string, ReplaySubject<PropertyResult>>> = {};

  blockAttributeValue<T>(virtualSlug: string, attributeName: string): Observable<T> {
    this.ensureBlockAttributeSubject(virtualSlug, attributeName);
    return this.internalCalculatedBlockAttributeValues[virtualSlug][attributeName];
  }

  validationRuleResults(virtualSlug: string, validationRuleIdentifier: string): Observable<PropertyResult> {
    this.ensureValidationRuleResultSubject(virtualSlug, validationRuleIdentifier);
    return this.internalValidationRuleResults[virtualSlug][validationRuleIdentifier];
  }

  distributeBlockAttributeValue(virtualSlug: string, attributeName: string, value: any) {
    this.ensureBlockAttributeSubject(virtualSlug, attributeName);
    this.internalCalculatedBlockAttributeValues[virtualSlug][attributeName].next(value);
    this.calculatedBlockAttributeValues.next({
      virtualSlug,
      attributeName,
      value
    });
  }

  distributeValidationRuleResults(virtualSlug: string, attributeName: string, result: PropertyResult) {
    this.ensureValidationRuleResultSubject(virtualSlug, attributeName);
    this.internalValidationRuleResults[virtualSlug][attributeName].next(result);
  }

  loadAggregatedCalculatedBlockAttributes(calculatedBlockAttributes: CalculatedBlockAttribute[]) {
    calculatedBlockAttributes.forEach(calculatedBlockAttribute => {
      this.distributeBlockAttributeValue(calculatedBlockAttribute.virtual_slug, 'required', calculatedBlockAttribute.required);
      this.distributeBlockAttributeValue(calculatedBlockAttribute.virtual_slug, 'visible', calculatedBlockAttribute.visible);
      this.distributeBlockAttributeValue(calculatedBlockAttribute.virtual_slug, 'html', calculatedBlockAttribute.html);
    });
  }

  loadConstantBlockAttributes(blocks: Block[]) {
    blocks?.forEach(block => {
      if (block.visible_calculation_type === RexCalculationType.CONSTANT) {
        this.distributeBlockAttributeValue(block.slug, 'visible', block.visible);
      }
      if (block.isInput && (block as Input).required_calculation_type === RexCalculationType.CONSTANT) {
        this.distributeBlockAttributeValue(block.slug, 'required', (block as Input).settings.required);
      }
    });
  }

  ngOnDestroy(): void {
    this.calculatedBlockAttributeValues.complete();
    Object.values(this.internalCalculatedBlockAttributeValues).forEach(v => {
      Object.values(v).forEach(v => {
        v.complete();
      });
    });
    Object.values(this.internalValidationRuleResults).forEach(v => {
      Object.values(v).forEach(v => {
        v.complete();
      });
    });
  }

  private ensureBlockAttributeSubject(virtualSlug: string, attributeName: string) {
    if (!this.internalCalculatedBlockAttributeValues[virtualSlug]) {
      this.internalCalculatedBlockAttributeValues[virtualSlug] = {};
    }
    if (!this.internalCalculatedBlockAttributeValues[virtualSlug][attributeName]) {
      this.internalCalculatedBlockAttributeValues[virtualSlug][attributeName] = new ReplaySubject(1);
    }
  }

  private ensureValidationRuleResultSubject(virtualSlug: string, attributeName: string) {
    if (!this.internalValidationRuleResults[virtualSlug]) {
      this.internalValidationRuleResults[virtualSlug] = {};
    }
    if (!this.internalValidationRuleResults[virtualSlug][attributeName]) {
      this.internalValidationRuleResults[virtualSlug][attributeName] = new ReplaySubject(1);
    }
  }
}
