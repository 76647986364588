import {inject, Injectable, OnDestroy} from '@angular/core';
import {Page, SubmitEventValue} from '@paperlessio/sdk/api/models';
import {BlockStore} from '@blocks/block/block.store';
import {SessionStore} from './session.store';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {catchError, concatMap, filter, first, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgForm, ValidationErrors} from '@angular/forms';
import {ToastService} from '@paperlessio/sdk/api/util';
import {captureException, captureMessage, withScope} from '@sentry/angular-ivy';
import {ParticipationSubmitEvent} from '@shared/submission/participation-submit-event';
import {BlockChangeDetectionService} from '@blocks/services/block-change-detection/block-change-detection.service';
import {NavigationService} from '@shared/submission-gizmo/navigation.service';
import {BreakpointObserver} from '@angular/cdk/layout';

/*
* This service is the brain of an active submission session.
* Orchestrates data flow, page validity and page transitions.
*/
@Injectable()
export class SessionControllerService implements OnDestroy {
  private subs = new Subscription();
  private pages: Page[];
  private page_number: number;
  private currentPageInternal: Page;
  private currentParticipantsBlockIds = [];

  private blockStore = inject(BlockStore);
  private sessionStore = inject(SessionStore);
  private router = inject(Router);
  private blockChangeDetectionService = inject(BlockChangeDetectionService);
  private toastService = inject(ToastService);
  private navigationService = inject(NavigationService);
  private breakpointObserver = inject(BreakpointObserver);

  form: NgForm;
  currentPage: Subject<Page> = new ReplaySubject<Page>(1);
  currentPageValid: Subject<boolean> = new ReplaySubject<boolean>(1);

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isSmall: boolean = false; // small screen without visible gizmo => disable gizmo actions
  mayComplete: boolean = true;

  init(form: NgForm): void {
    if (!form) {
      throw new Error('cannot initalize without a form');
    }

    this.form = form;

    this.subs.add(this.navigationService.nextPageTrigger.subscribe(() => {
      this.goToNextPage();
    }));

    this.subs.add(this.form.statusChanges
      .subscribe(status => {
        this.currentPageValid.next(status);
      }));

    this.currentPageValid.next(this.form.valid);

    this.subs.add(this.form.valueChanges
      .subscribe(values => {
        this.sessionStore.setBlockValues(this.form.form.getRawValue());
      }));

    this.subs.add(combineLatest([
      this.sessionStore.submissionMetaInformation,
      this.blockStore.blocks.pipe(filter(blocks => !!blocks?.length)),
      this.blockStore.pages().pipe(filter(pages => !!pages?.length))
    ])
      .pipe(first())
      .subscribe(value => {
        const [pmi, blocks, pages] = value;

        // calculate which blocks need to be filled by the active participant
        this.currentParticipantsBlockIds = [];
        for (const [block_id, participant_ids] of Object.entries(pmi.block_owner_mapping)) {
          if (participant_ids.indexOf(pmi.participant?.id) > -1) {
            this.currentParticipantsBlockIds.push(+block_id);
          }
        }

        // set pages from store and mark ready
        this.pages = pages as Page[];
        this.markAsReady();
      }));

    this.subs.add(this.currentPage.subscribe(page => this.currentPageInternal = page));

    this.subs.add(this.breakpointObserver.observe('(max-width: 1310px)').subscribe(value => {
      this.isSmall = value.matches;
    }));

    this.subs.add(this.sessionStore.mayComplete.subscribe(mayComplete => {
      this.mayComplete = mayComplete;
    }));
  }

  // second half of switching page: set the given page as current page
  setCurrentPage(page_number?: number) {
    if (page_number) {
      this.page_number = +page_number;
    }
    if (this.pages?.length && this.page_number > 0) {
      this.currentPage.next(this.pages[this.page_number - 1]);
    }
  }

  goToPreviousPage() {
    this.goToPage(this.page_number - 1);
  }

  goToNextPage() {
    if (this.form.valid || this.form.disabled) {
      if (!!this.loading.value) {
        return;
      }

      if (!this.mayComplete) {
        this.toastService.error('session.may_complete.cannot_next');
        return;
      }

      this.loading.next(true);

      this.persistCurrentPage().subscribe({
        next: success => {
          this.goToPage(this.page_number + 1);
          this.loading.next(false);
        },
        error: error => {
          this.loading.next(false);
        }
      });
    } else if (this.isSmall) {
      // this is here until the gizmo has proper mobile support!
      this.toastService.warning('submit.missing_data');
      this.markAllInputsAsTouched();
    } else {
      this.navigationService.gizmoAction();
    }
  }

  save() {
    this.loading.next(true);

    const values = Object.fromEntries(Object.entries(this.form.controls)
      .filter(([slug, control]) => control.valid && control.dirty)
      .map(([slug, control]) => [slug, control.value]));

    return this.sessionStore
      .submitValues(this.filterExcludedSlugs(values), this.currentPageInternal.id)
      .pipe(
        tap({
          next: success => {
            this.patchLocalFormAndSubmissionValues(success.submit_event_values);

            this.toastService.success('submit_events.create.success');
            this.loading.next(false);
          },
          error: error => {
            this.toastService.error('submit_events.create.error');

            withScope(scope => {
              scope.setLevel('error');
              scope.setExtra('error object', error);
              scope.setExtra('participant_token', this.sessionStore.participant_token);
              scope.setExtra('submissionMetaInformation', this.sessionStore.submissionMetaInformation.value);
              scope.setExtra('form values', this.form.form.getRawValue());
              captureMessage(`[SessionController] submit_events.create.error`);
            });

            throw error;
          }
        })
      );
  }

  // represents first half of switching page: save stuff and navigate via router
  goToPage(page_number: number) {
    if (page_number > 0 && page_number - 1 < this.pages.length) {
      this.router
        .navigate(['s', this.sessionStore.participant_token, 'p', page_number])
        .catch(err => console.log('error', err));
    } else {
      captureException('Tried to visit nonexistent page.');
    }
  }

  // check if requested page is available (yet) before actually setting it
  // if the requested page is not yet reachable, because preceding pages are yet to be completed,
  // we will redirect to the first non-complete page
  // TODO: this will be refactored based on the planned new tracking concept
  attemptToLoadPage(page_number: number) {
    this.setCurrentPage(page_number);
  }

  get hasPreviousPage() {
    return this.page_number > 1;
  }

  get hasNextPage() {
    return this.page_number < this.pages?.length;
  }

  get isLastPage() {
    return this.page_number === this.pages?.length;
  }

  get pageCount() {
    return this.pages?.length;
  }

  /**
   * Starts with 1
   */
  get currentPageNumber() {
    return this.page_number;
  }

  completeSession() {
    if (this.form.valid || this.form.disabled) {
      if (!!this.loading.value) {
        return;
      }

      if (!this.mayComplete) {
        this.toastService.error('session.may_complete.cannot_complete');
        return;
      }

      this.loading.next(true);

      this.persistCurrentPage()
        .pipe(concatMap(_ => this.sessionStore.complete()))
        .subscribe({
          next: async completion => {
            if (completion.redirect_url) {
              window.location.href = completion.redirect_url;
            } else {
              await this.router.navigate(['/', 's', this.sessionStore.participant_token, 'completed']);
            }
          },
          error: error => {
            // TODO: Error handling
            // Auth Errors (CSRF, Session not found) => Auto fix by sending GET /hello and POST /session
            // 0 => Servers not available
            // 500 => Internal Error, please try again later or contact support
            // 422 => better message depending on the error message

            withScope(scope => {
              scope.setLevel('error');
              scope.setExtra('error object', error);
              scope.setExtra('participant_token', this.sessionStore.participant_token);
              scope.setExtra('submissionMetaInformation', this.sessionStore.submissionMetaInformation.value);
              scope.setExtra('form values', this.form.form.getRawValue());
              captureMessage(`[SessionController] submit_events.create.error`);
            });

            this.toastService.error('completion.create.error');

            this.loading.next(false);
          }
        });
    } else if (this.isSmall) {
      // this is here until the gizmo has proper mobile support!
      this.toastService.warning('submit.missing_data');
      this.markAllInputsAsTouched();

      if (this.hasValidationError('uploading')) {
        this.toastService.warning('submit.uploading');
      }
    } else {
      this.markAllInputsAsTouched();
      this.navigationService.gizmoAction();

      if (this.hasValidationError('uploading')) {
        this.toastService.warning('submit.uploading');
      }
    }
  }

  private hasValidationError(errorKey: string): boolean {
    let hasError = false;

    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.form.get(key)?.errors;
      hasError = controlErrors && controlErrors.hasOwnProperty(errorKey);
    });

    return hasError;
  }

  isParticipantAnOwnerOf(id: number) {
    return this.currentParticipantsBlockIds.indexOf(id) >= 0;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private persistCurrentPage(): Observable<ParticipationSubmitEvent> {
    return this.sessionStore
      .submitValues(this.filterExcludedSlugs(this.form.value), this.currentPageInternal.id)
      .pipe(
        catchError(error => {
          withScope(scope => {
            scope.setLevel('error');
            scope.setExtra('error object', error);
            scope.setExtra('participant_token', this.sessionStore.participant_token);
            scope.setExtra('submissionMetaInformation', this.sessionStore.submissionMetaInformation.value);
            scope.setExtra('form values', this.form.form.getRawValue());
            captureMessage(`[SessionController] submit_events.create.error`);
          });

          this.toastService.error('submit_events.create.error');
          throw error;
        }),
        tap({
          next: value => {
            this.patchLocalFormAndSubmissionValues(value.submit_event_values);
          }
        })
      );
  }

  private markAsReady() {
    if (this.pages?.length && this.ready.value === false) {
      this.ready.next(true);
    }
  }

  private markAllInputsAsTouched() {
    this.form.form.markAllAsTouched();
    // cool, but in the UI nothing will change because of OnPush
    // get the slugs, get the corresponding block ids and mark them as changed
    const slugKeys: string[] = Object.keys(this.form.form.controls);
    const inputBlockIds = this.blockStore.blocksArray.filter(b => slugKeys.indexOf(b.slug) >= 0).map(b => b.id);
    this.blockChangeDetectionService.setChangedBlockIds(inputBlockIds);
  }

  private filterExcludedSlugs(data: { [slug: string]: any }): { [slug: string]: any } {
    const filteredData = {};
    Object.keys(data).filter(key => !key.includes('@excluded-slug') ? filteredData[key] = data[key] : null);
    return filteredData;
  }

  private patchLocalFormAndSubmissionValues(submitEventValues: SubmitEventValue[]) {
    submitEventValues.forEach(sev => {
      // patch the form control with the new value without emitting an event to prevent infinite loops
      this.form?.controls?.[sev.slug]?.setValue(sev.value, {emitEvent: false});

      if (this.sessionStore?.submission?.value?.values) {
        // patch the submission value with the full object
        this.sessionStore.submission.value.values[sev.slug] = sev;
      }
    });
  }
}
